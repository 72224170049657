.App {
  text-align: center;
}

header { 
  font-family: "Palatino Linotype", "Book Antiqua", Palatino, serif;
}

.time {
  font-size: 250px;
  font-weight: bold;
}

.date {
  font-size: 116px;
}

small {
  font-size: 40%;
}